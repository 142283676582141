enum Role {
	SuperAdmin = 'SuperAdmin',
	Support = 'Support',
	FlavorAdmin = 'FlavorAdmin',
	FlavorUser = 'FlavorUser',
}

const pages = new Map();
// prettier-ignore
// eslint-disable-next-line no-lone-blocks
{
  pages.set('home',                       [Role.SuperAdmin, Role.Support, Role.FlavorAdmin, Role.FlavorUser]);
  pages.set('users',                      [Role.SuperAdmin, Role.Support, Role.FlavorAdmin, Role.FlavorUser]);
  pages.set('user',                       [Role.SuperAdmin, Role.Support, Role.FlavorAdmin, Role.FlavorUser]);
  pages.set('createUser',                 [Role.SuperAdmin, Role.Support, Role.FlavorAdmin]);
  pages.set('productOverview',            [Role.SuperAdmin, Role.Support, Role.FlavorAdmin, Role.FlavorUser]);
  pages.set('branches',                   [Role.SuperAdmin, Role.Support, Role.FlavorAdmin, Role.FlavorUser]);
  pages.set('orders',                     [Role.SuperAdmin, Role.Support, Role.FlavorAdmin, Role.FlavorUser]);
  pages.set('createFlavor',               [Role.SuperAdmin]);
  pages.set('kundenanlage',               [Role.SuperAdmin]);
  pages.set('encryptdecrypt',             [Role.SuperAdmin]);
  pages.set('usermanagement',             [Role.SuperAdmin]);
  
  //table-orders
  pages.set('tableorders', [Role.SuperAdmin, Role.FlavorAdmin, Role.Support]);
}

const features = new Map();
// prettier-ignore
// eslint-disable-next-line no-lone-blocks
{
  // general
  features.set('selectFlavor',            [Role.SuperAdmin, Role.Support]);

	// users
  features.set('exportUsers',             [Role.SuperAdmin, Role.FlavorAdmin]);
  features.set('userIdColumn',            [Role.SuperAdmin, Role.Support]);
  features.set('userOverview',            [Role.SuperAdmin, Role.Support, Role.FlavorAdmin, Role.FlavorUser]);
  features.set('orderHistory',            [Role.SuperAdmin, Role.Support, Role.FlavorAdmin, Role.FlavorUser]);
  features.set('shoppingHistory',         [Role.SuperAdmin, Role.Support, Role.FlavorAdmin, Role.FlavorUser]);
  features.set('chargingHistory',         [Role.SuperAdmin, Role.Support, Role.FlavorAdmin, Role.FlavorUser]);
  features.set('manuelEmailConfirmation', [Role.SuperAdmin, Role.Support, Role.FlavorAdmin, Role.FlavorUser]);
  features.set('resendconfirmationmail',  [Role.SuperAdmin, Role.Support, Role.FlavorAdmin, Role.FlavorUser]);
  features.set('resetPassword',           [Role.SuperAdmin, Role.Support, Role.FlavorAdmin, Role.FlavorUser]);
  features.set('unblockCustomerCard',     [Role.SuperAdmin]);
  features.set('blockCustomerCard',       [Role.SuperAdmin]);
  features.set('chargeCustomerCard',      [Role.SuperAdmin]);
  features.set('deleteUser',              [Role.SuperAdmin]);

  // create user
  features.set('inviteUserWithRole',      [Role.SuperAdmin, Role.Support, Role.FlavorAdmin]);
	features.set('inviteFlavorAdminUser',   [Role.SuperAdmin, Role.Support]);
	features.set('inviteSupportUser',       [Role.SuperAdmin]);
	features.set('inviteSuperAdminUser',    [Role.SuperAdmin]);
  features.set('createUserManually',      [Role.SuperAdmin, Role.Support, Role.FlavorAdmin]);
  features.set('createUserWithRole',      [Role.SuperAdmin]);
  features.set('creatFlavorAdminUser',    [Role.SuperAdmin]);
  features.set('creatSupportUser',        [Role.SuperAdmin]);
	features.set('creatSuperAdminUser',     [Role.SuperAdmin]);


	// branches
  features.set('orderOverview',           [Role.SuperAdmin, Role.Support, Role.FlavorAdmin, Role.FlavorUser]);
  features.set('productAvailability',     [Role.SuperAdmin, Role.Support, Role.FlavorAdmin, Role.FlavorUser]);

	// kundenanlage
  features.set('editFlavor',              [Role.SuperAdmin]);
  features.set('sanityCheck',             [Role.SuperAdmin]);
  features.set('paymentProviderSetting',             [Role.SuperAdmin]);
  features.set('fullSync',                [Role.SuperAdmin]);
  features.set('emailSmtpServer',[Role.SuperAdmin]);

	// encryptdecrypt
  features.set('encrypt',                 [Role.SuperAdmin]);
  features.set('decrypt',                 [Role.SuperAdmin]);

  // usermanagement
  features.set('userManagerIdColumn',     [Role.SuperAdmin]);
  features.set('changeRole',              [Role.SuperAdmin, Role.Support, Role.FlavorAdmin]);
  features.set('changeToFlavorAdminUser', [Role.SuperAdmin, Role.Support]);
	features.set('changeToSupportUser',     [Role.SuperAdmin]);
	features.set('changeToSuperAdminUser',  [Role.SuperAdmin]);

  
}

export const hasAccess = (role: string, toCheck: string): boolean => {
	// use the next line for testing different roles
	// role = 'Support';
	let allowedRoles = pages.get(toCheck);
	if (allowedRoles != null && allowedRoles.includes(role)) return true;

	allowedRoles = features.get(toCheck);
	if (allowedRoles != null && allowedRoles.includes(role)) return true;
	return false;
};
