import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Grid } from '@material-ui/core';
import TableOrderAdminInfoTable from '../../components/TableOrderAdminInfoTable/TableOrderAdminInfoTable';
import BranchTableOrderInfoTable from '../../components/BranchTableOrderInfoTable/BranchTableOrderInfoTable';
import { getBranchTables, getTables, patchBranchTable, getSettings } from '../../api/tableorders'; 
import { SnackBarContext } from '../../components/CustomAlertSnackBar/CustomAlertSnackBarContext';
import {
	G2GTableOrderSetting,
	StoreTableOrderInfo, 
	TableOrderAdminInfo
} from '../../types/tableordersmodels';
import Loadingspinner from '../../components/LoadingSpinner/Loadingspinner';
import { LoginContext } from '../../contexts/LoginContext';
import { LoginContextType } from '../../types/loginContext';
import './TableOrders.css';

const TableOrders = () => {
	const { selectedFlavor }: LoginContextType = useContext(LoginContext);
	const [branchTables, setBranchTables] = useState<StoreTableOrderInfo[]>([]);
	const [tables, setTables] = useState<TableOrderAdminInfo[]>([]);
	const [branchTablesChanged, setBranchTablesChanged ] = useState<boolean>(false);
	const { displaySnackbar } = useContext(SnackBarContext);

	useEffect(() => {
		setIsLoading(true);
		doApiCalls();
setBranchTablesChanged(false);
		function doApiCalls() {
		
		 
		getBranchTables(selectedFlavor.flavor)
		.then((value) => {
			if (value !== null ){
				const bt =value.sort((a,b)=>a.storeNumber-b.storeNumber) 
				setBranchTables(bt) 
			} 
	}).catch((error) => console.error('Fehler beim holen die Filiale Tischbestellung Bestellbarkeit.', error));
		
	getTables(selectedFlavor.flavor)
	.then((value) => {
		if (value !== null ){
			
			const t =value.sort((a,b)=>a.storeNumber-b.storeNumber) 
			setTables(t) 
		} 
}).catch((error) => console.error('Fehler beim holen die Tischen Informationen.', error));
	
setIsLoading(false);
	}},  [selectedFlavor]);

 const triggerSetBranchTablesChanged = ( ) => {
		if(!branchTablesChanged) setBranchTablesChanged(true);
	};

	const changeTableOrderAllowed = (branchID:number, tableOrderAllowed:boolean ) => {
	var btToChange=	branchTables.find(x=>x.storeID==branchID)  
	if(btToChange)
	btToChange.tableOrderAllowed=tableOrderAllowed
	};

	const patchBranchTablesPreordability = async()=>{
		if(!branchTablesChanged) return; 
		const res = await patchBranchTable(selectedFlavor.flavor, branchTables)
		if(res ==-1) {displaySnackbar('Die Änderungen an der Vorbestellungsmöglichkeit für die Filiale konnten nicht gespeichert werden.', 'error');return;}
			displaySnackbar('Die Änderungen an der Vorbestellungsmöglichkeit für die Filiale wurden erfolgreich gespeichert.', 'success');
			setBranchTablesChanged(false);
	}

	const [isLoading, setIsLoading] = useState<boolean>(false);

	if (selectedFlavor.flavor === 'all') {
		return (
			<div className="branch__noFlavorSelected">
				Es muss ein Flavor ausgewählt werden um die Filialen anzeigen zu können
			</div>
		);
	}

	if (isLoading) {
		return <Loadingspinner />;
	}
else{
	return (
		<Container maxWidth="xl">
			<Grid container spacing={3}>
				<Grid item xs={8}  >
					<div className="grid__title">Bestellbarkeit der Filialen</div>
					<Grid className='branchtables_grid'>
					{branchTablesChanged && <Button onClick={patchBranchTablesPreordability} style={{margin:'1% 2%'}} color="secondary" variant="text"  >
							Änderungen speichern
						</Button> }
						<BranchTableOrderInfoTable
						data={branchTables} setChanged={triggerSetBranchTablesChanged} changeTableOrderAllowed={changeTableOrderAllowed}
					/>
					
					</Grid>
				</Grid>
				<Grid item xs={12}  >
					<div className="grid__title">Tisch Informationen</div>
					<TableOrderAdminInfoTable data={tables}   />
				</Grid>
			</Grid>
		</Container>
	);}
};

export default TableOrders;
